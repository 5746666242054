<template>
  <div class="flex flex-col space-y-10">
    <h1 class="text-center text-2xl">No items found for this search</h1>
    <div class="flex justify-center w-full">
      <button class="btn rounded-none w-52 bg-black" @click="onToolbarInit">
        Back to all Items
      </button>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["toolbarInit"],

  methods: {
    onToolbarInit: function () {
      this.$emit("toolbarInit");
    },
  },
};
</script>
