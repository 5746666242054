<template>
  <div
    class="
        market__layout
        grid-cols-1
        md:grid-cols-2
        lg:grid-cols-3
        xxl:grid-cols-4
        "
  >
    <article
      class="blur-md duration-300"
      :class="{ 'filter blur-3xl': isBlur }"
      v-for="i in ds"
      :key="i.id"
    >
      <!-- <MarketFixedItem
        v-if="i.order_type === 1"
        :id="i.id"
        :title="i.title"
        :image="i.thumbnail"
        :pen_name="i.creator_pen_name"
        :num_price="i.num_price"
        :visual_price="i.visual_price"
        :asset_symbol="i.assetSymbol"
        :orderState="i.order_state"
        :fileType="i.sub_content_type"
      /> -->
      <market-fixed-item
        v-if="i.order_type === 1"
        :orderState="i.order_state"
        :id="i.id"
        :content_id="i.content_id"
        :img="i.thumbnail"
        :title="i.title"
        :price="i.visual_price"
        :symbol="i.assetSymbolImg"
      />
      <!-- <MarketAuctionItem
        v-else
        :id="i.id"
        :title="i.title"
        :image="i.thumbnail"
        :pen_name="i.creator_pen_name"
        :bottom_num_price="i.bottom_num_price"
        :bottom_visual_price="i.bottom_visual_price"
        :asset_symbol="i.assetSymbol"
        :price_begin="i.price_begin"
        :sold_price="i.sold_price"
        :end_at="i.end_at"
        :created_at="i.created_at"
        :orderState="i.order_state"
        :fileType="i.sub_content_type"
      /> -->
      <market-auction-item
        v-else
        :orderState="i.order_state"
        :id="i.id"
        :content_id="i.content_id"
        :img="i.thumbnail"
        :title="i.title"
        :price="i.bottom_visual_price"
        :symbol="i.assetSymbolImg"
        :end_at="i.end_at"
      />
    </article>
  </div>

  <!-- <section>
    <div
      class="
        grid grid-cols-1
        md:grid-cols-2
        lg:grid-cols-3
        gap-y-16
        place-items-center
      "
    >
      <article
        class="blur-md duration-300"
        :class="{ 'filter blur-3xl': isBlur }"
        v-for="i in ds"
        :key="i.id"
      >
        <MarketFixedItem
          v-if="i.order_type === 1"
          :id="i.id"
          :title="i.title"
          :image="i.thumbnail"
          :pen_name="i.creator_pen_name"
          :num_price="i.num_price"
          :visual_price="i.visual_price"
          :asset_symbol="i.assetSymbol"
          :orderState="i.order_state"
          :fileType="i.sub_content_type"
        />
        <MarketAuctionItem
          v-else
          :id="i.id"
          :title="i.title"
          :image="i.thumbnail"
          :pen_name="i.creator_pen_name"
          :bottom_num_price="i.bottom_num_price"
          :bottom_visual_price="i.bottom_visual_price"
          :asset_symbol="i.assetSymbol"
          :price_begin="i.price_begin"
          :sold_price="i.sold_price"
          :end_at="i.end_at"
          :created_at="i.created_at"
          :orderState="i.order_state"
          :fileType="i.sub_content_type"
        />
      </article>
    </div>
  </section> -->
</template>

<script>
import MarketFixedItem from "./items/MarketFixedItem.vue";
import MarketAuctionItem from "./items/MarketAuctionItem.vue";

export default {
  props: {
    ds: Object,
  },

  data: function() {
    return {
      isBlur: false,
    };
  },

  components: {
    MarketFixedItem,
    MarketAuctionItem,
  },

  methods: {
    blur(val) {
      this.isBlur = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.market__layout {
  display: grid;
  gap: 4rem;
  grid-template-rows: minmax(300px, 55vh);
  grid-auto-rows: minmax(300px, 55vh);
  // .artwork__layout {
  //   display: grid;
  //   grid-template-columns: 1fr;
  //   grid-template-rows: 3.5fr 1fr;
  //   .artwork__description {
  //     background-color: #fff;
  //     display: grid;
  //     grid-template-columns: 1fr 1fr;
  //     grid-template-rows: 1fr 1fr;
  //     .artwork__description__title {
  //       align-self: end;
  //       margin-bottom: 0.2rem;
  //       font-size: 1.3rem;
  //       font-weight: 700;
  //       grid-column: 1 / 3;
  //       @extend %text__ellipsis;
  //     }
  //     .artwork__description__penname {
  //       font-size: 0.9rem;
  //       color: $gray;
  //       margin-right: 2rem;
  //       @extend %text__ellipsis;
  //     }
  //     .artwork__description__price {
  //       font-size: 0.9rem;
  //       color: $gray;
  //       text-align: end;
  //       margin-left: 2rem;
  //       @extend %text__ellipsis;
  //     }
  //   }
  // }
}

img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
