<template>
  <div v-click-outside="onFocusOut">
    <nav>
      <div class="container flex justify-between">
        <button class="cursor-pointer px-4">
          <span
            @click="onFilterToggle"
            class="hover__border"
            :class="{ clicked__border: isOpen }"
          >
            Filter</span
          >
        </button>

        <div class="flex w-full sm:w-1/2">
          <div
            class="
            flex
            justify-between
            w-8/12
            py-3
            sm:py-2 sm:mx-auto
            text-gray-600
          "
          >
            <input
              class="
              w-11/12
              border-0
              bg-white
              h-10
              text-sm
              focus:outline-none focus:text-black
              text-right text-gray
              mr-1
            "
              name="search"
              id="searchbar"
              placeholder="Search by Art, Artist or #hashtag"
              v-model="search_param"
              @keyup.enter="onInputSearch"
            />
            <button class="cursor-pointer">
              <svg
                @click="onInputSearch"
                class="h-4 w-4 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                viewBox="0 0 56.966 56.966"
                style="enable-background: new 0 0 56.966 56.966"
                xml:space="preserve"
                width="512px"
                height="512px"
              >
                <path
                  d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
                />
              </svg>
            </button>
          </div>

          <section class="my-auto z-20" id="theme">
            <div class="group inline-block">
              <button
                class="
                flex
                outline-none
                focus:outline-none
                py-3
                px-3
                rounded-sm
                items-center
                w-full
                sm:w-44
                text-sm
              "
              >
                <span class="flex-1 pr-1 sm:border-l border-gray-soft">{{
                  sort_title
                }}</span>
                <span>
                  <svg
                    class="
                    fill-current
                    h-4
                    w-4
                    transform
                    group-hover:-rotate-180
                    transition
                    duration-150
                    ease-in-out
                  "
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </span>
              </button>
              <ul
                class="
                option-list
                mt-2
                w-36
                sm:w-48
                bg-white
                overflow-hidden
                market_filter_shadow
                transform
                scale-0
                group-hover:scale-100
                absolute
                transition
                duration-80
                ease-in-out
                origin-top
                min-w-42 min-h-42
              "
              >
                <li
                  class="
                  option
                  block
                  px-4
                  py-2
                  text-sm text-gray
                  hover__bg--gray-soft
                "
                  :class="{ 'selected bg-black': sort.recentActivity }"
                  @click="
                    resetSort();
                    sort.recentActivity = !sort.recentActivity;
                    sort_title = 'Recent Activity';
                    onSearch();
                  "
                >
                  Recent Activity
                </li>
                <li
                  class="
                  option
                  block
                  px-4
                  py-2
                  text-sm text-gray
                  hover__bg--gray-soft
                "
                  :class="{ 'selected bg-black': sort.endingSoon }"
                  @click="
                    resetSort();
                    sort.endingSoon = !sort.endingSoon;
                    sort_title = 'Ending Soon';
                    onSearch();
                  "
                >
                  Ending Soon
                </li>
                <li
                  class="
                  option
                  block
                  px-4
                  py-2
                  text-sm text-gray
                  hover__bg--gray-soft
                "
                  :class="{ 'selected bg-black': sort.lowestPrice }"
                  @click="
                    resetSort();
                    sort.lowestPrice = !sort.lowestPrice;
                    sort_title = 'Lowest Price';
                    onSearch();
                  "
                >
                  Lowest Price
                </li>
                <li
                  class="
                  option
                  block
                  px-4
                  py-2
                  text-sm text-gray
                  hover__bg--gray-soft
                "
                  :class="{ 'selected bg-black': sort.highestPrice }"
                  @click="
                    resetSort();
                    sort.highestPrice = !sort.highestPrice;
                    sort_title = 'Highest Price';
                    onSearch();
                  "
                >
                  Highest Price
                </li>
                <li
                  class="
                  option
                  block
                  px-4
                  py-2
                  text-sm text-gray
                  hover__bg--gray-soft
                "
                  :class="{ 'selected bg-black': sort.newest }"
                  @click="
                    resetSort();
                    sort.newest = !sort.newest;
                    sort_title = 'Newest';
                    onSearch();
                  "
                >
                  Newest
                </li>
                <li
                  class="
                  option
                  block
                  px-4
                  py-2
                  text-sm text-gray
                  hover__bg--gray-soft
                "
                  :class="{ 'selected bg-black': sort.oldest }"
                  @click="
                    resetSort();
                    sort.oldest = !sort.oldest;
                    sort_title = 'Oldest';
                    onSearch();
                  "
                >
                  Oldest
                </li>
              </ul>
            </div>
          </section>
        </div>
      </div>
    </nav>
    <div
      class="
        absolute
        shadow-2xl
        bg-white
        toolbar__width
        duration-300
        p-10
        z-20"
      :class="{ hidden: !isOpen }"
      @blur="onFocusOut"
    >
      <div class="box relative">
        <div class="flex absolute right-0 top-0">
          <div
            class="
                hover__border-black
                cursor-pointer
                text-sm
                font-medium
                mr-5
              "
            @click="onFilterInit"
          >
            Clear
          </div>
          <div class="mr-5" @click="onFocusOut">
            <svg
              width="20"
              height="20"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="cursor-pointer"
            >
              <path
                d="M13.0724 12.4972L24.8818 0.687841C25.0392 0.530412 25.0392 0.275219 24.8818 0.11779C24.7244 -0.0395888 24.4692 -0.0395888 24.3118 0.11779L12.5023 11.9272L0.692939 0.11779C0.532789 -0.0368676 0.277596 -0.032433 0.122888 0.127717C-0.0279897 0.283937 -0.0279897 0.531621 0.122888 0.687841L11.9323 12.4972L0.122888 24.3067C-0.0372621 24.4614 -0.0416967 24.7165 0.113011 24.8767C0.267719 25.0368 0.522912 25.0413 0.683062 24.8866C0.686439 24.8834 0.689714 24.88 0.692939 24.8767L12.5023 13.0673L24.3118 24.8767C24.4719 25.0314 24.7271 25.027 24.8818 24.8668C25.0327 24.7106 25.0327 24.4629 24.8818 24.3067L13.0724 12.4972Z"
                fill="#262626"
              />
            </svg>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="text-sm my-2 mr-2 ml-1 md:m-2">
            <label
              class="
                  block
                  tracking-wide
                  parula__text-black
                  text-xs
                  sm:text-sm
                  font-bold
                  mb-2
                "
              for="grid-first-name"
            >
              SEARCH TYPE
            </label>
          </div>

          <div class="flex justify-start md:ml-2 mb-6">
            <button
              class="
                  px-2
                  lg:py-3
                  sm:px-16
                  py-1
                  text-center text-sm
                  parula__text-black
                  bg-white
                  m-1
                  border
                  parula__border-black
                  hover-black
                "
              :class="{
                'parula__text-white parula__bg-black': filter.type.all,
              }"
              @click="
                resetFilterType();
                filter.type.all = !filter.type.all;
              "
            >
              All
            </button>
            <button
              class="
                  px-2
                  lg:py-3
                  sm:px-16
                  py-1
                  text-center text-sm
                  bg-white
                  m-1
                  border
                  parula__border-black
                  hover-black
                "
              :class="{
                'parula__text-white parula__bg-black': filter.type.auctionOnly,
              }"
              @click="
                resetFilterType();
                filter.type.auctionOnly = !filter.type.auctionOnly;
              "
            >
              Auction Only
            </button>
            <button
              class="
                  px-2
                  lg:py-3
                  sm:px-16
                  py-1
                  text-center text-sm
                  bg-white
                  m-1
                  border
                  parula__border-black
                  hover-black
                "
              :class="{
                'parula__text-white parula__bg-black':
                  filter.type.fixedPriceOnly,
              }"
              @click="
                resetFilterType();
                filter.type.fixedPriceOnly = !filter.type.fixedPriceOnly;
              "
            >
              Fixed Price Only
            </button>
            <button
              class="
                  px-2
                  lg:py-3
                  sm:px-16
                  py-1
                  text-center text-sm
                  bg-white
                  m-1
                  border
                  parula__border-black
                  hover-black
                "
              :class="{
                'parula__text-white parula__bg-black': filter.type.genesis,
              }"
              @click="
                resetFilterType();
                filter.type.genesis = !filter.type.genesis;
              "
            >
              Genesis Price
            </button>
          </div>
          <div>
            <div class="flex flex-col md:flex-row mb-6">
              <div class="w-full md:w-1/6 md:pl-3 mb-2 sm:mb-0">
                <label
                  class="
                      block
                      uppercase
                      tracking-wide
                      parula__text-black
                      text-sm
                      font-bold
                      mb-2
                    "
                  for="grid-last-name"
                >
                  Mint After
                </label>
                <input
                  v-model="filter.date.mint_after"
                  class="
                      appearance-none
                      block
                      w-full
                      bg-white
                      text-gray text-center
                      border
                      parula__border-black
                      py-2
                      px-4
                      mb-3
                      leading-tight
                      focus:outline-none focus:text-black
                    "
                  id="grid-last-name"
                  type="text"
                  placeholder="YYYY-MM-DD"
                />
              </div>
              <div class="w-full md:w-1/6 md:pl-3 mb-2 sm:mb-0">
                <label
                  class="
                      block
                      uppercase
                      tracking-wide
                      parula__text-black
                      text-sm
                      font-bold
                      mb-2
                    "
                  for="grid-first-name"
                >
                  Mint before
                </label>
                <input
                  v-model="filter.date.mint_before"
                  class="
                      appearance-none
                      block
                      w-full
                      bg-white
                      text-gray text-center
                      border
                      parula__border-black
                      py-2
                      px-4
                      mb-3
                      leading-tight
                      focus:outline-none focus:text-black
                    "
                  id="grid-first-name"
                  type="text"
                  placeholder="YYYY-MM-DD"
                />
              </div>
            </div>
          </div>

          <div class="ml-2">
            <label
              class="
                  block
                  uppercase
                  tracking-wide
                  parula__text-black
                  text-sm
                  font-bold
                  mb-2
                "
            >
              TOKEN TYPE
            </label>
            <div class="flex justify-start">
              <button
                class="
                    px-2
                    lg:py-3
                    sm:px-16
                    py-1
                    parula__text-black
                    text-center text-sm
                    bg-white
                    m-1
                    border
                    parula__border-black
                    hover-black
                  "
                :class="{
                  'parula__text-white parula__bg-black': filter.token.all,
                }"
                @click="
                  resetFilterToken();
                  filter.token.all = !filter.token.all;
                "
              >
                All
              </button>
              <button
                class="
                    px-2
                    lg:py-3
                    sm:px-16
                    py-1
                    text-center text-sm
                    bg-white
                    m-1
                    border
                    parula__border-black
                    hover-black
                  "
                :class="{
                  'parula__text-white parula__bg-black': filter.token.ETH,
                }"
                @click="
                  resetFilterToken();
                  filter.token.ETH = !filter.token.ETH;
                "
              >
                ETH
              </button>
              <!-- <button
                  class="
                    px-2
                    lg:py-3
                    sm:px-16
                    py-1
                    text-center text-sm
                    bg-white
                    m-1
                    border
                    parula__border-black
                    hover-black
                  "
                  :class="{
                    'parula__text-white parula__bg-black': filter.token.TKN,
                  }"
                  @click="
                    resetFilterToken();
                    filter.token.TKN = !filter.token.TKN;
                  "
                >
                  TKN
                </button> -->
              <button
                class="
                    px-2
                    lg:py-3
                    sm:px-16
                    py-1
                    text-center text-sm
                    bg-white
                    m-1
                    border
                    parula__border-black
                    hover-black
                  "
                :class="{
                  'parula__text-white parula__bg-black': filter.token.WETH,
                }"
                @click="
                  resetFilterToken();
                  filter.token.WETH = !filter.token.WETH;
                "
              >
                WETH
              </button>
              <button
                class="
                    px-2
                    lg:py-3
                    sm:px-16
                    py-1
                    text-center text-sm
                    bg-white
                    m-1
                    border
                    parula__border-black
                    hover-black
                  "
                :class="{
                  'parula__text-white parula__bg-black': filter.token.KLAY,
                }"
                @click="
                  resetFilterToken();
                  filter.token.KLAY = !filter.token.KLAY;
                "
              >
                KLAY
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
            mt-4
            flex flex-row flex-wrap
            md:flex-nowrap
            justify-center
            md:justify-end
            items-baseline
          "
      >
        <div>
          <button
            class="
                px-2
                lg:py-3
                sm:px-16
                py-1
                text-center text-sm
                bg-white
                m-1
                border
                parula__border-black
                hover-black
              "
            @click="onSearch()"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  </div>
  <hr class="absolute left-0 w-full" />
</template>

<script>
window.onload = function() {
  function onClickOption(e) {
    const selectedValue = e.currentTarget.innerHTML;
    document.querySelector("#theme .text").innerHTML = selectedValue;
  }

  var optionList = document.querySelectorAll("#theme .option");
  for (var i = 0; i < optionList.length; i++) {
    var option = optionList[i];
    option.addEventListener("click", onClickOption);
  }
};

import vClickOutside from "click-outside-vue3";

export default {
  // emits: {
  //   filterToggle: Boolean,
  //   search: Object,
  // },

  emits: ["filterToggle", "search"],

  data: function() {
    return {
      isOpen: false,
      category: "",

      sort_title: "Newest",

      filter: {
        type: {
          all: true,
          auctionOnly: false,
          fixedPriceOnly: false,
          genesis: false,
        },

        date: {
          mint_before: "",
          mint_after: "",
        },

        token: {
          all: true,
          ETH: false,
          // TKN: false,
          WETH: false,
          KLAY: false,
        },
      },

      search_param: "",

      sort: {
        recentActivity: false,
        endingSoon: false,
        lowestPrice: false,
        highestPrice: false,
        newest: true,
        oldest: false,
      },
    };
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  watch: {
    async $route() {
      await this.checkCategory();
    },
  },

  methods: {
    checkCategory() {
      const { category } = this.$route.query;
      this.category = category;
    },

    setSearchParam: function(val) {
      this.search_param = val;
    },

    resetSearchParam: function() {
      this.search_param = "";
    },

    resetFilterType: function() {
      this.filter.type = {
        all: false,
        auction_only: false,
        fixedPriceOnly: false,
        genesis: false,
      };
    },

    resetFilterToken: function() {
      this.filter.token = {
        all: false,
        ETH: false,
        // TKN: false,
        WETH: false,
        KLAY: false,
      };
    },

    resetSort: function() {
      this.sort = {
        recentActivity: false,
        endingSoon: false,
        lowestPrice: false,
        highestPrice: false,
        newest: false,
        oldest: false,
      };
    },

    initSort: function() {
      this.sort_title = "Newest";

      this.sort = {
        recentActivity: false,
        endingSoon: false,
        lowestPrice: false,
        highestPrice: false,
        newest: true,
        oldest: false,
      };
    },

    onFilterInit: function() {
      this.filter = {
        type: {
          all: true,
          auctionOnly: false,
          fixedPriceOnly: false,
          genesis: false,
        },

        date: {
          mint_before: "",
          mint_after: "",
        },

        token: {
          all: true,
          ETH: false,
          // TKN: false,
          WETH: false,
          KLAY: false,
        },
      };
    },

    // 기존에 있던 함수
    // 수정 후
    open(val) {
      this.isOpen = val;
    },

    onFilterToggle() {
      this.isOpen = !this.isOpen;
      this.$emit("filterToggle", this.isOpen);
    },

    onInputSearch() {
      this.onSearch();
    },

    onSearch() {
      let type, token, search, sort, date, category;

      const type_check = (type = Object.keys(this.filter.type).find(
        (key) => this.filter.type[key] === true
      ));

      const token_check = (type = Object.keys(this.filter.token).find(
        (key) => this.filter.token[key] === true
      ));

      const sort_check = (type = Object.keys(this.sort).find(
        (key) => this.sort[key] === true
      ));

      if (type_check) {
        type_check === "all" ? (type = undefined) : (type = type_check);
      }

      if (token_check) {
        token_check === "all" ? (token = undefined) : (token = token_check);
      }

      if (sort_check) {
        sort = sort_check;
      }

      search = this.search_param;
      date = this.filter.date;
      category = this.category;

      const ds = {
        type,
        token,
        search,
        sort,
        date,
        category,
      };

      this.$emit("search", ds);
      this.onFocusOut();
    },

    // 수정 전
    onFocusOut() {
      // alert("s");
      this.isOpen = false;
      this.$emit("filterToggle", this.isOpen);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_layout.scss";
@import "@/scss/_variables.scss";
@import "@/scss/_button.scss";

.button__green {
  font-weight: 600;
  @include button-radius(8rem, 2.5rem, #20A97F, $white);
}

.button__border__green {
  font-weight: 600;
  @include button-white-radius(8rem, 2.5rem, #20A97F, #20A97F);
  // &:hover {
  //   @include button-radius(10rem, 2rem, $black, $white);
  //   border: 1px, solid, $black;
  // }
}

.toolbar__width {
  width: 80%;
  @include xxl {
    width: 1440px;
  }
}
.hover__bg--gray-soft:hover {
  background-color: #dddddd;
}

.hover__border-black:hover {
  border-color: #262626;
  border-bottom-width: 2px;
}
.hover__border:hover {
  border-color: #dddddd;
  border-bottom-width: 2px;
}
.clicked__border {
  border-color: #262626;
  border-bottom-width: 2px;
}

.toolbar__shadow {
  box-shadow: 0 2px 1px -2px gray;
}

.hover-black:hover {
  background-color: #262626;
  color: #fff;
}
.hover-text-blue-edit:hover {
  color: #064994;
}
.group:hover .group-hover\:scale-100 {
  transform: scale(1);
}
.group:hover .group-hover\:-rotate-180 {
  transform: rotate(180deg);
}
.scale-0 {
  transform: scale(0);
}
.selected {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
</style>
