<template>
  <article>
    <div class="my-wrapper w-full h-full mx-auto">
      <div>
        <MarketToolbar
          ref="market_toolbar"
          @search="search"
          @filter-toggle="onFilter"
        />
      </div>

      <div v-if="loading">
        <parula-loading />
      </div>

      <div v-else>
        <div v-if="itemList.length === 0 && loading === false" class="mt-36">
          <NoItem @toolbarInit="toolbarInit" />
        </div>

        <div v-else class="mt-24">
          <MarketGrid ref="market_grid" :ds="itemList" />

          <div v-if="itemList.length !== 0" class="flex justify-center mt-24">
            <button
              class="
                py-2
                px-4
                bg-white
                border border-black
                hover:bg-black hover:text-white
                text-black text-xs
                md:text-base
                button-black
              "
              type="button"
              @click="onSeeMore"
            >
              View More
            </button>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import MarketToolbar from "./subs/MarketToolbar.vue";
import MarketGrid from "./subs/MarketGrid.vue";
import OpenapiService from "../../services/openapi.service";
import NoItem from "../../components/NoItem.vue";
import ParulaLoading from "@/components/ParulaLoading.vue";
import { removeUnusedZero, setVisualPrice } from "../../util/price";

export default {
  data: function() {
    return {
      itemList: [],
      loading: false,
      DISPLAYING_DIGITS: 3,
      page_size: 20,
      init_page_size: 20,
      search_param: {},
      category: "",
    };
  },

  components: {
    MarketToolbar,
    MarketGrid,
    NoItem,
    ParulaLoading,
  },

  watch: {
    async $route() {
      const { name } = this.$route;

      if (name === "MarketPage") {
        await this.checkCategory();
        await this.setSearchParam();
        this.$refs.market_toolbar.onFilterInit();
        this.loadOrderList();
      }
    },
  },

  async mounted() {
    await this.checkCategory();
    await this.setSearchParam();
    this.loadOrderList();
  },

  methods: {
    setSearchParam() {
      //Artist Spotlight 클릭시 파룰라 마켓리스트에서 VincentMcindoe 작품만 리스팅 되도록 손성익대표님 요청으로 추가됨
      //https://parula.io/market/?artist=VincentMcindoe  <= 링크로 동작 하도록 하기 위함
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      const ds = {
        type: undefined,
        token: undefined,
        search: params.artist,
        sort: "newest",
        date: {
          mint_before: "",
          mint_after: "",
        },
        category: this.category,
      };

      this.search_param = ds;
      //Artist Spotlight 클릭시 파룰라 마켓리스트에서 VincentMcindoe 작품만 리스팅 되도록 손성익대표님 요청으로 추가됨

      let { hashtag } = this.$route.query;

      if (hashtag) {
        hashtag = `#${hashtag}`;
        this.$refs.market_toolbar.setSearchParam(hashtag);
        this.search_param.search = hashtag;
      }
    },

    checkCategory() {
      const { category } = this.$route.query;
      this.category = category;
    },

    // 수정 후
    onSeeMore() {
      this.page_size = this.page_size + this.init_page_size;
      this.loadOrderList();
    },

    search(params) {
      this.search_param = params;
      this.page_size = this.init_page_size;
      this.loadOrderList();
    },

    loadOrderList() {
      this.loading = true;
      //Artist Spotlight 클릭시 파룰라 마켓리스트에서 VincentMcindoe 작품만 리스팅 되도록 손성익대표님 요청으로 추가됨
      if (this.search_param.search)
        this.$refs.market_toolbar.setSearchParam(this.search_param.search);
      //Artist Spotlight 클릭시 파룰라 마켓리스트에서 VincentMcindoe 작품만 리스팅 되도록 손성익대표님 요청으로 추가됨
      OpenapiService.orderDisplayList(
        1,
        this.page_size,
        this.search_param
      ).then((res) => {
        console.log("res ==> ", res);
        this.itemList = res.data.items;
        for (var i = 0; i < this.itemList.length; i++) {
          if (this.itemList[i].order_type === 1) {
            //일반판매일경우
            this.itemList[i].num_price = removeUnusedZero(
              this.itemList[i].price_fixed,
              this.itemList[i].assetDecimals
            );
            this.itemList[i].visual_price = setVisualPrice(
              this.itemList[i].num_price,
              this.DISPLAYING_DIGITS
            );
          } else {
            //경매일경우 경매시작가
            this.itemList[i].bottom_num_price = removeUnusedZero(
              this.itemList[i].price_begin,
              this.itemList[i].assetDecimals
            );
            this.itemList[i].bottom_visual_price = setVisualPrice(
              this.itemList[i].bottom_num_price,
              this.DISPLAYING_DIGITS
            );
            //경매일경우 최고입찰가 구현 필요
          }
        }

        this.loading = false;
      });
    },

    toolbarInit: function() {
      this.$refs.market_toolbar.onFilterInit();
      this.$refs.market_toolbar.initSort();
      this.$refs.market_toolbar.resetSearchParam();

      this.$refs.market_toolbar.onSearch();
    },

    // 수정 전
    onFilter(isOpen) {
      if (!this.$refs.market_grid) return;

      this.isGridBlur = isOpen;
      this.$refs.market_grid.blur(isOpen);
      this.$refs.market_toolbar.open(isOpen);
    },

    // 현재 안 쓰지만 필요해 보임
    clicked: function(id) {
      this.$emit("clickDetail", id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_button.scss";

#logo-other {
  display: inline-block;
}

.button-black {
  @include button-radius(8rem, 2.5rem, $black);
}
</style>
