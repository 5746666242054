<template>
  <div class="layout">
    <div class="layout__image">
      <img
        src="@/assets/img/cilo_loading.gif"
        alt="Hold a seconds"
        class="w-28 h-28"
      />
      <span>Please wait...</span>
    </div>
  </div>
</template>

<style scoped>
.layout {
  height: 92vh;
}

.layout__image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 84vh;
}
</style>
