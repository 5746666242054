<template>
  <div class="relative w-full h-full card-radius card-shadow">
    <auction-card
      :order-state="orderState"
      :id="content_id"
      :img="img"
      :title="title"
      :price="price"
      :symbol="symbol"
      :end_at="end_at"
    />

    <div
      v-if="orderState === 3"
      class="
        absolute
        bottom-40
        w-full
        sold-out__cover
        bg-black bg-opacity-70
        text-left
        flex flex-col
        items-start
        justify-center        
        py-5
        px-3
      "
    >
      <div class="flex justify-center w-full">
        <div class="text-center text-lg parula__text-gray-soft font-semibold">
          SOLD OUT
        </div>
      </div>
    </div>

    <router-link
      :id="'hover_div_' + id"
      :to="`/market/order/${id}`"
      class="
        z-10
        absolute
        inset-0
        bg-black
        text-center
        flex flex-col
        items-center
        justify-center
        opacity-0
        hover:opacity-100
        bg-opacity-90
        duration-300
        card-radius
      "
    >
      <h1 class="tracking-wider parula__text-gray-soft">See More</h1>
    </router-link>
  </div>
</template>

<script>
import AuctionCard from "@/components/card/AuctionCard.vue";

export default {
  props: {
    orderState: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    content_id: {
      type: Number,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    symbol: {
      type: String,
      required: true,
    },
    end_at: {
      type: String,
      required: true,
    },
  },

  components: {
    AuctionCard,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.card-shadow {
  &:hover {
    box-shadow: 0.5rem 0.5rem 1rem 0px rgb(38, 38, 38, 0.2);
  }
}

.card-radius {
  border-radius: $radius $radius $radius $radius;
}

.sold-out__cover {
  height: calc(100% - 10rem);
  border-radius: $radius $radius 0 0;
}

// .card-shadow {
//   box-shadow: 0.5rem 0.5rem 1rem 0px rgb(38, 38, 38, 0.05);
//   &:hover {
//     box-shadow: 0.5rem 0.5rem 1rem 0px rgb(38, 38, 38, 0.2);
//   }
// }
</style>
